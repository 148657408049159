import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLazyQuery } from '@apollo/react-hooks';
import * as l from 'lodash';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import { ErrorDialog } from '../components/common';
import AuthenticatedPage from '../components/authenticated-page';
import {
  navigate,
  checkJWTExpired,
  parseGraphQLErrorMessage,
  getToken,
  getRoleId,
  getRegistrationType,
} from '../utils/utils';

import { RoleId } from '../constants';
import { GET_UPDATE_REQUEST_LIST } from '../crud/update-request';
import UpdateRequestTable from '../components/update-request-list/update-request-table';

function VaspUpdateRequestList() {
  const token = getToken();
  const roleId = getRoleId(token);

  const [updateRequestList, setUpdateRequestList] = useState([]);
  const [searchText, setSearchText] = useState('');

  // VASP status in backoffice
  const [pendingOnApprover, setPendingOnApprover] = useState(roleId === RoleId.ADMIN);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [getUpdateRequestList, getUpdateRequestListResult] = useLazyQuery(GET_UPDATE_REQUEST_LIST, {
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      const vaspUpdateRequestList = l.get(data, 'getVaspUpdateRequestList');
      if (vaspUpdateRequestList) {
        setUpdateRequestList(
          vaspUpdateRequestList.map((vur) => ({
            ...vur,
            registration_type_str: getRegistrationType(vur.registration_type),
          })),
        );
      } else {
        setUpdateRequestList([]);
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getUpdateRequestList();
    }
  }, []);

  const handleEditActionClick = (rowData) => {
    const parameter = { id: rowData.id };
    navigate('/review-vasp-update-request', { state: { parameter } });
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'VASP Update Request List']}
          breadcrumbLinks={['/', '/vasp-update-request-list']}
        />
        <div style={{ maxWidth: '100%', marginTop: '2em' }}>
          <UpdateRequestTable
            updateRequestList={updateRequestList || []}
            onEditActionClick={handleEditActionClick}
            searchText={{ get: searchText, set: setSearchText }}
            pendingOnApprover={{ set: setPendingOnApprover, get: pendingOnApprover }}
            loading={getUpdateRequestListResult.loading}
          />
        </div>
        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
      </AuthenticatedPage>
    </Layout>
  );
}

export default VaspUpdateRequestList;
