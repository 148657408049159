/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, Table, TableContainer, TablePagination } from '@material-ui/core';
import UpdateRequestTableToolbar from './UpdateRequestTableToolbar';
import UpdateRequestTableHead from './UpdateRequestTableHead';
import UpdateRequestTableBody from './UpdateRequestTableBody';
import { useStyles, useToolbarStyles } from './styles';

function UpdateRequestTable(props) {
  const {
    selectedVaspIds,
    updateRequestList,
    onSelectionChange,
    onEditActionClick,
    searchText,
    loading,
  } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validateData = updateRequestList.filter((data) => data.checkable);

  const genHeadCells = () => [
    { id: 'icons', numeric: false, label: '', disablePadding: false, width: '5%' },
    { id: 'email', numeric: false, label: 'Email', disablePadding: false, width: '15%' },
    {
      id: 'registration_type',
      numeric: false,
      label: 'Registration Type',
      disablePadding: false,
      width: '10%',
    },
    { id: 'vasp_code', numeric: false, label: 'VASP code', disablePadding: false, width: '10%' },
    { id: 'vasp_name', numeric: false, label: 'VASP name', disablePadding: false, width: '20%' },
    {
      id: 'go_live_timestamp',
      numeric: false,
      label: 'Go-live timestamp',
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'account_expiration_dt_timestamp',
      numeric: false,
      label: 'Expiration Date',
      disablePadding: false,
      width: '10%',
    },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, width: '15%' },
  ];

  const headCells = genHeadCells();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UpdateRequestTableToolbar classes={useToolbarStyles()} searchText={searchText} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="approve table"
          >
            <UpdateRequestTableHead
              headCells={headCells}
              classes={classes}
              rowCount={validateData.length}
            />
            <UpdateRequestTableBody
              headCells={headCells}
              rows={updateRequestList}
              page={page}
              rowsPerPage={rowsPerPage}
              onSelectionChange={onSelectionChange}
              onEditActionClick={onEditActionClick}
              loading={loading}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={updateRequestList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

UpdateRequestTable.propTypes = {
  selectedVaspIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateRequestList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operation: PropTypes.string,
      vasp_code_prefix: PropTypes.string,
      country_code: PropTypes.string,
      city_code: PropTypes.string,
      vasp_name: PropTypes.string,
      go_live_dt: PropTypes.string,
      vasp_status: PropTypes.string,
      checkable: PropTypes.bool,
      vasp_code: PropTypes.string,
    }),
  ).isRequired,
  onEditActionClick: PropTypes.func.isRequired,
  searchText: PropTypes.shape({
    get: PropTypes.string,
    set: PropTypes.func,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UpdateRequestTable;
