import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Tooltip } from '@material-ui/core';

function RegultaionTableToolbar(props) {
  const { classes, numSelected, searchText } = props;
  const disableClear = !searchText.get;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          id="selectionTitle"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Update Request list
        </Typography>
      )}

      <div style={{ flexDirection: 'row' }}>
        <TextField
          id="search_input"
          placeholder="Search"
          value={searchText.get}
          onChange={(e) => {
            searchText.set(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Tooltip title="clear">
                  <IconButton
                    id="search_clear_button"
                    component="div"
                    disabled={disableClear}
                    aria-label="clear search text"
                    onClick={() => {
                      searchText.set('');
                    }}
                  >
                    <ClearIcon color="inherit" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Toolbar>
  );
}

RegultaionTableToolbar.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    highlight: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  numSelected: PropTypes.number.isRequired,
  searchText: PropTypes.shape({
    get: PropTypes.string,
    set: PropTypes.func,
  }).isRequired,
};

export default RegultaionTableToolbar;
