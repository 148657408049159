import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

function RegulationTableHead(props) {
  const { headCells } = props;

  const renderTableCell = (headCell) => {
    if (headCell.id === 'icons') {
      return <TableCell key={headCell.id} padding="checkbox" width={headCell.width} />;
    }
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? 'right' : 'left'}
        padding={headCell.disablePadding ? 'none' : 'default'}
        width={headCell.width}
      >
        {headCell.label}
      </TableCell>
    );
  };
  return (
    <TableHead>
      <TableRow>{headCells.map((headCell) => renderTableCell(headCell))}</TableRow>
    </TableHead>
  );
}

RegulationTableHead.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      disablePadding: PropTypes.bool,
      width: PropTypes.string,
    }),
  ).isRequired,
};

export default RegulationTableHead;
